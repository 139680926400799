<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink">
                    Contact
                </h2>
                <p>
                    We are happy when our customers are happy! If you have any questions related to our website or if you
                    are experiencing any difficulties, please contact us by e-mail. If you are experiencing billing
                    problems, please contact us by e-mail or call our billing hotline.<br><br>
                </p>

                <div class="padded shadow clearfix">
                    <div class="col-md-4">
                        <strong class="pink">Billing support:</strong> <a :href="'https://' + instanceInfo?.supportDomain"
                            target="_blank">{{ instanceInfo?.supportDomain.split('.')[0] }}</a><br>
                        <strong class="pink">E-mail:</strong> <a :href="'mailto:' + instanceInfo?.supportEmail">
                            {{ instanceInfo?.supportEmail }}
                        </a><br>
                        <strong class="pink">Phone:</strong> <a :href="'tel:' + instanceInfo?.phoneNumber">{{
                            instanceInfo?.phoneNumber }}</a>
                        <br> <small>Only for Credit Card inquiries</small>
                    </div>
                    <div class="col-md-8">
                        Other inquiries (non-billing) can be submitted using the form below.
                    </div>
                </div>

                <p></p>

                <form accept-charset="UTF-8" class="contact" @submit.prevent="submitForm">
                    <div class="form-group">
                        <label for="name">Name:</label>
                        <input v-model="username" class="form-control" name="name" type="text" id="name" required>
                    </div>
                    <div class="form-group">
                        <label for="email">E-mail:</label>
                        <input v-model="email" class="form-control" name="email" type="email" id="email" required>
                    </div>
                    <div class="form-group">
                        <label for="message">Message:</label>
                        <textarea v-model="message" class="form-control" maxlength="800" name="message" cols="50" rows="10"
                            id="message" required></textarea>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-pink"
                            :disabled="submitSuccessful || submitFailed">Send</button>
                    </div>
                </form>
                <div v-if="submitSuccessful" class="alert alert-success alert-dismissible">
                    <button @click="clearSuccessMessage" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Thank you for contacting us, we have received your message!</p>
                </div>
                <div v-if="submitFailed" class="alert alert-danger alert-dismissible">
                    <button @click="clearFailureMessage" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Your message couldn't be delivered. Please try contacting us by email!</p>
                </div>
                <br>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import { ref } from 'vue';
import { registerContactMessage } from '@/app/api/contact';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Contact',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup() {
        const submitSuccessful = ref(false);
        const submitFailed = ref(false);

        const username = ref('');
        const email = ref('');
        const message = ref('');

        const submitForm = () => {
            const contactRequest = {
                username: username.value,
                email: email.value,
                message: message.value,
            };
            registerContactMessage(contactRequest)
                .then(() => {
                    username.value = '';
                    email.value = '';
                    message.value = '';
                })
                .catch(() => {
                    submitFailed.value = true;
                });
        };

        const clearSuccessMessage = () => {
            submitSuccessful.value = false;
        };

        const clearFailureMessage = () => {
            submitFailed.value = false;
        };

        return {
            submitSuccessful,
            submitFailed,
            username,
            email,
            message,
            submitForm,
            clearSuccessMessage,
            clearFailureMessage
        };
    },
};
</script>
