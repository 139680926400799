<template>
    <div class="row">
        <h3 align="center">A sneak peak of what you will find!</h3>
        <ul class="bxslider">
            <li v-for="member in instanceInfo.membersSample" :key="member.name">
                <a href="#" class="scrollToSubscribe">
                    <img :src="'/uploads/150/' + member.image" :alt="member.name">
                    <span class="left">{{ member.name }}</span>
                    <span class="right">{{ member.age }}</span>
                </a>
            </li>
        </ul>
        <span id="act-prev"></span>
        <span id="act-next"></span>
    </div><!-- End row -->
</template>
    
<script>
export default {
    name: 'MembersPreview',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
    mounted: function () {
        document.getElementById('slider-script')?.remove();
        const sliderPlugin = document.createElement('script');
        sliderPlugin.src = '/theme/001/js/slider.js';
        sliderPlugin.id = 'slider-script';
        sliderPlugin.async = true;
        document.body.appendChild(sliderPlugin);
    },
};
</script>
    