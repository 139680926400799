import { http } from '@/app/helpers/axios-instance';

export const signupUser = (user) =>
  http.post(`/v1/users`, user)
    .then(response => response.data);

export const cancelSubscription = () =>
  http.post(`/v1/user/subscription/cancel`)
    .then(response => response.data);

export const changePassword = (request) =>
  http.post(`/v1/user/change-password`, request)
    .then(response => response.data);

export const makePayment = (paymentRequest) =>
  http.post(`/v1/payment`, paymentRequest)
    .then(response => response.data);

export const makeTopUpPayment = (topUpRequest) =>
  http.post(`/v1/top-up`, topUpRequest)
    .then(response => response.data);

export const activateUser = (uuid) =>
  http.post(`/v1/users/${uuid}/activate`)
    .then(response => response.data);

export const cancelSubscriptionByUuid = (uuid) =>
  http.post(`/v1/users/${uuid}/subscription/cancel`)
    .then(response => response.data);