<template>
    <div class="home">
        <Header :instanceInfo="instanceInfo" />

        <div class="main">
            <div class="container-fluid">
                <div class="row">
                    <div class="formcont"></div><!--/formcont-->
                    <div class="formcont2">
                        <div class="col-md-12 hero-content">
                            <div class="registration-form mobile-hide-bak">
                                <form class="form-horizontal" @submit.prevent="submitForm">

                                    <div class="registration-form-top">
                                        <h2 style="margin-top:15px;">Sign up for <strong>FREE</strong>!</h2>
                                        <h4>No creditcard required</h4>
                                    </div><!--/registration-form-top-->
                                    <div class="registration-form-bottom">

                                        <div class="form-group">
                                            <label for="gender" class="col-sm-5 control-label">I am a</label>
                                            <div class="col-sm-7">
                                                <select v-model="userGender" class="form-control" id="gender" name="gender">
                                                    <option value="MALE">Man</option>
                                                    <option value="FEMALE">Woman</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="seek" class="col-sm-5 control-label">Looking to chat with</label>
                                            <div class="col-sm-7">
                                                <select v-model="lookingForGender" class="form-control" id="seek"
                                                    name="seek">
                                                    <option value="FEMALE">Women</option>
                                                    <option value="MALE">Men</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="birthdate" class="col-sm-5 control-label">Date of birth</label>
                                            <div class="col-sm-7 form-inline">
                                                <select v-model="birthDay" class="form-control input-sm" name="birth_day"
                                                    style="float:left; max-width:60px; margin-right:3px;">
                                                    <option value="01">1st</option>
                                                    <option value="02">2nd</option>
                                                    <option value="03">3rd</option>
                                                    <option value="04">4th</option>
                                                    <option value="05">5th</option>
                                                    <option value="06">6th</option>
                                                    <option value="07">7th</option>
                                                    <option value="08">8th</option>
                                                    <option value="09">9th</option>
                                                    <option value="10">10th</option>
                                                    <option value="11">11th</option>
                                                    <option value="12">12th</option>
                                                    <option value="13">13th</option>
                                                    <option value="14">14th</option>
                                                    <option value="15">15th</option>
                                                    <option value="16">16th</option>
                                                    <option value="17">17th</option>
                                                    <option value="18">18th</option>
                                                    <option value="19">19th</option>
                                                    <option value="20">20th</option>
                                                    <option value="21">21st</option>
                                                    <option value="22">22nd</option>
                                                    <option value="23">23rd</option>
                                                    <option value="24">24th</option>
                                                    <option value="25">25th</option>
                                                    <option value="26">26th</option>
                                                    <option value="27">27th</option>
                                                    <option value="28">28th</option>
                                                    <option value="29">29th</option>
                                                    <option value="30">30th</option>
                                                    <option value="31">31st</option>
                                                </select>

                                                <select v-model="birthMonth" class="form-control input-sm"
                                                    name="birth_month"
                                                    style="float:left; max-width:65px; margin-right:3px;">
                                                    <option value="01">Jan</option>
                                                    <option value="02">Feb</option>
                                                    <option value="03">March</option>
                                                    <option value="04">April</option>
                                                    <option value="05">May</option>
                                                    <option value="06">June</option>
                                                    <option value="07">July</option>
                                                    <option value="08">Aug</option>
                                                    <option value="09">Sept</option>
                                                    <option value="10">Oct</option>
                                                    <option value="11">Nov</option>
                                                    <option value="12">Dec</option>
                                                </select>

                                                <select v-model="birthYear" class="form-control input-sm" name="birth_year"
                                                    style="float:left; max-width:80px;">
                                                    <option v-for="n in 100"
                                                        :value="(new Date().getFullYear() - n - 17).toString()"
                                                        :key="new Date().getFullYear() - n - 17">{{ new Date().getFullYear()
                                                            - n - 17 }}
                                                    </option>
                                                </select>
                                            </div>

                                        </div>

                                        <div class="form-group">
                                            <label for="region" class="col-sm-5 control-label">Country</label>
                                            <div class="col-sm-7">
                                                <select v-model="country" name="location" id="locationInput"
                                                    class="form-control"
                                                    :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                                    <option value="">Select your country</option>
                                                    <option v-for="country in allowedCountries" :value="country"
                                                        :key="country">{{ country }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="email" class="col-sm-5 control-label">Email</label>
                                            <div class="col-sm-7">
                                                <input v-model="email" class="form-control" maxlength="100"
                                                    placeholder="Email" name="email" type="email" id="email"
                                                    :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="username" class="col-sm-5 control-label">Username</label>
                                            <div class="col-sm-7">
                                                <input v-model="username" class="form-control" maxlength="15"
                                                    placeholder="Your Username" name="username" type="text" id="username"
                                                    :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="password" class="col-sm-5 control-label">Password</label>
                                            <div class="col-sm-7">
                                                <input v-model="password" class="form-control" placeholder="Password"
                                                    name="password" type="password" id="password"
                                                    :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="password" class="col-sm-5 control-label">Password (again)</label>
                                            <div class="col-sm-7">
                                                <input v-model="confirmPassword" class="form-control"
                                                    placeholder="Confirm password" name="confirm_password" type="password"
                                                    id="confirm_password"
                                                    :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <hr>
                                                <div v-if="errorMessage" class="alert alert-danger alert-dismissible">
                                                    <button @click="errorMessage = null" type="button" class="close"
                                                        data-dismiss="alert" aria-label="Close"><span
                                                            aria-hidden="true">×</span></button>
                                                    <p>{{ errorMessage }}</p>
                                                </div>
                                                <div class="term-cond" style="padding:0;">
                                                    <label style="font-weight:100;">
                                                        <input
                                                            style="appearance: checkbox!important; -webkit-appearance: checkbox!important; -moz-appearance: checkbox!important;"
                                                            name="terms" type="checkbox" value="1"
                                                            :required="country == '' && email == null && username == null && password == null && confirmPassword == null ? null : true">
                                                        By signing up I certify that (1) I am at least 18 years old and have
                                                        reached the age of majority in my jurisdiction to use this dating
                                                        site, (2) have read and agree to the <a href="/terms"
                                                            target="_blank" rel="nofollow">Terms of Service</a>, and I
                                                        understand how the Website will process my personal data in
                                                        accordance with the <a href="/privacy" target="_blank"
                                                            rel="nofollow">Privacy Statement</a>.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <input type="submit" class="btn btn-primary btn-block btn-pink"
                                                    value="JOIN NOW!">
                                            </div>
                                            <div class="mobile-only center">
                                                <br /><a href="#" class="btnShowLogin">Already a member? Log in</a>
                                            </div>
                                        </div>

                                    </div><!--/registration-form-bottom-->
                                </form> <!-- /form -->
                            </div><!--/registration-form-->
                            <ul class="stylewhite">
                                <li>Find a chatting mate online</li><br>
                                <li>Flirt about your fantasies in a secure environment</li><br>
                                <li>Ready for some excitement in your life?</li>
                            </ul>
                        </div><!--/col-md-12-->
                    </div><!--/formcont2-->
                </div><!--/row-->

            </div><!--/container-->
        </div><!--/main-->

        <div class="most-active-members">
            <div class="container">
                <MembersPreview :instanceInfo="instanceInfo" />
            </div><!-- End Container -->

        </div><!--/most-active-members-->

        <div class="container smallx">
            <div class="row padded clearfix">
                <ul class="three-icons">
                    <li><img src="/theme/001/images/i1.png" alt="">Find a chatting mate online</li>
                    <li><img src="/theme/001/images/i2.png" alt="">Flirt about your fantasies in a secure environment</li>
                    <li><img src="/theme/001/images/i3.png" alt="">Ready for some excitement in your life?</li>
                </ul>
                <p align="center"><a class="btn btn-large scrollToSubscribe" href="#"
                        style="text-transform: uppercase;">Register now!</a></p>
            </div><!--/row-->
        </div><!--/container-->
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import { ref } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import moment from 'moment';
import MembersPreview from '@/app/components/MembersPreview';
import { signupUser } from '@/app/api/user';
import { redirectToRoute } from '@/app/router';

export default {
    name: 'Home',
    components: { Header, Footer, MembersPreview },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup() {

        const allowedCountries = ['Algeria', 'Andorra', 'Angola', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Bolivia', 'Bosnia & Herzegovina', 'Botswana', 'Brazil', 'Bulgaria', 'Cambodia', 'Cameroon', 'Canada', 'Central African Rep.', 'Chad', 'Chile', 'China', 'Colombia', 'Congo (Dem. Rep.)', 'Congo (Rep.)', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'French Guiana', 'French Polynesia', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Guatemala', 'Guinea', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Korea (North)', 'Korea (South)', 'Kuwait', 'Kyrgyzstan', 'Latvia', 'Lebanon', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Morocco', 'Mozambique', 'Myanmar (Burma)', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Panama', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russia', 'San Marino', 'Saudi Arabia', 'Senegal', 'Serbia', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa', 'Spain', 'Sudan', 'Sweden', 'Switzerland', 'Taiwan', 'Thailand', 'Tunisia', 'Turkey', 'Ukraine', 'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 'United States of America', 'Uruguay', 'Venezuela', 'Vietnam'];

        const userGender = ref('MALE');
        const lookingForGender = ref('FEMALE');
        const birthDay = ref('01');
        const birthMonth = ref('01');
        const birthYear = ref('1980');
        const country = ref('')
        const email = ref(null);
        const username = ref(null);
        const password = ref(null);
        const confirmPassword = ref(null);

        const errorMessage = ref('');

        const submitForm = () => {
            const birthDateString = birthYear.value + '-' + birthMonth.value + '-' + birthDay.value;
            if (!moment(birthDateString, "YYYY-MM-DD", true).isValid() || moment().diff(birthDateString, 'years', false) < 18) {
                errorMessage.value = "Invalid birthdate";
                return;
            }

            if (country.value == '' && email.value == null && username.value == null && password.value == null && confirmPassword.value == null) {
                redirectToRoute('/credits/subscription/direct-premium');
            }

            if (password.value.length < 8) {
                errorMessage.value = 'Password must be at least 8 characters';
                return;
            }
            if (confirmPassword.value !== password.value) {
                errorMessage.value = 'Password must be confirmed in [Password (again)] field';
                return;
            }
            const signupRequest = {
                gender: userGender.value,
                lookingForGender: lookingForGender.value,
                birthDate: birthDateString,
                country: country.value,
                email: email.value,
                username: username.value,
                password: password.value
            }
            signupUser(signupRequest)
                .then(() => {
                    errorMessage.value = null;
                    redirectToRoute('/signup/success');
                })
                .catch(() => {
                    errorMessage.value = "Can't sign up. Try again later or contact our support!";
                });
        };

        return {
            allowedCountries,
            userGender,
            lookingForGender,
            birthDay,
            birthMonth,
            birthYear,
            country,
            email,
            username,
            password,
            confirmPassword,
            errorMessage,
            submitForm
        };
    },
};
</script>
